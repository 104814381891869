exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liput-js": () => import("./../../../src/pages/liput.js" /* webpackChunkName: "component---src-pages-liput-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-lost-password-js": () => import("./../../../src/pages/lost-password.js" /* webpackChunkName: "component---src-pages-lost-password-js" */),
  "component---src-pages-lunasta-virtuaalikoodi-js": () => import("./../../../src/pages/lunasta-virtuaalikoodi.js" /* webpackChunkName: "component---src-pages-lunasta-virtuaalikoodi-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-sv-biljetter-js": () => import("./../../../src/pages/sv/biljetter.js" /* webpackChunkName: "component---src-pages-sv-biljetter-js" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-tulevat-messut-js": () => import("./../../../src/pages/tulevat-messut.js" /* webpackChunkName: "component---src-pages-tulevat-messut-js" */),
  "component---src-pages-vuosien-varrelta-js": () => import("./../../../src/pages/vuosien-varrelta.js" /* webpackChunkName: "component---src-pages-vuosien-varrelta-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-basic-sub-page-js": () => import("./../../../src/templates/basicSubPage.js" /* webpackChunkName: "component---src-templates-basic-sub-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-expo-article-jsx": () => import("./../../../src/templates/expo/article.jsx" /* webpackChunkName: "component---src-templates-expo-article-jsx" */),
  "component---src-templates-expo-collection-article-jsx": () => import("./../../../src/templates/expo/collectionArticle.jsx" /* webpackChunkName: "component---src-templates-expo-collection-article-jsx" */),
  "component---src-templates-expo-collection-premise-jsx": () => import("./../../../src/templates/expo/collectionPremise.jsx" /* webpackChunkName: "component---src-templates-expo-collection-premise-jsx" */),
  "component---src-templates-expo-index-jsx": () => import("./../../../src/templates/expo/index.jsx" /* webpackChunkName: "component---src-templates-expo-index-jsx" */),
  "component---src-templates-expo-subpage-jsx": () => import("./../../../src/templates/expo/subpage.jsx" /* webpackChunkName: "component---src-templates-expo-subpage-jsx" */),
  "component---src-templates-expo-virtual-jsx": () => import("./../../../src/templates/expo/virtual.jsx" /* webpackChunkName: "component---src-templates-expo-virtual-jsx" */),
  "component---src-templates-internal-release-page-js": () => import("./../../../src/templates/internalReleasePage.js" /* webpackChunkName: "component---src-templates-internal-release-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-premise-page-js": () => import("./../../../src/templates/premisePage.js" /* webpackChunkName: "component---src-templates-premise-page-js" */),
  "component---src-templates-press-release-page-js": () => import("./../../../src/templates/pressReleasePage.js" /* webpackChunkName: "component---src-templates-press-release-page-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

