import React from "react";
import getFirebaseInstance from "./src/firebase";
import loadFirebaseDependencies from "./src/firebase/loadFirebaseDependencies";
import { signInWithUid } from "./src/hooks/autoLogin/loginWithUid";
import { navigate } from "gatsby"
/*eslint-disable */
export function onClientEntry() {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
/*eslint-enable */

export function onRouteUpdate({ location }) {
  if (location.pathname === '/extra-naantali') {
    window.location.href = 'https://vanha.asuntomessut.fi/extra-naantali';
  }
  if (location.href && location.href.startsWith('https://liput.asuntomessut.fi/')) {
    window.location.href = 'https://www.asuntomessut.fi/liput';
  }
  if (localStorage.getItem('app') === 'true' && !location.search.includes('app=true')) {
    navigate(`${location.pathname}?app=true`, { replace: true });
  }
}

export function onInitialClientRender() {
  loadFirebaseDependencies.then(app => {
    const firebaseInstance = getFirebaseInstance(app)
    if (typeof window === undefined) {
      return 
    }
    if (!firebaseInstance) {
      return 
    }
    const urlParams = new URLSearchParams(window.location.search);
    const isApp = urlParams.get('app');
    if (!isApp) {
      return 
    }
    localStorage.setItem('app', isApp);
    const uid = urlParams.get('uid');
    const idToken = urlParams.get('idToken')
    const userCode = urlParams.get('code')
    signInWithUid(uid, idToken, firebaseInstance, userCode)

  })
}