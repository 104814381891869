export async function signInWithUid(uid, idToken, firebase, userCode) {
  try {
    const response = await fetch(`https://us-central1-virtuaaliset-asuntomessu-dad9b.cloudfunctions.net/verifyToken?uid=${uid}&idToken=${idToken}`);
    const result = await response.json();
    
    if (result.idTokenBelongsToUid) {
      const tokenResult = await firebase.generateToken({ uid: uid });
      const token = tokenResult.data.token;
      await firebase.signInWithToken({token});
      const emailResponse = await fetch(`https://us-central1-virtuaaliset-asuntomessu-dad9b.cloudfunctions.net/getUserByUid?uid=${uid}`);
      const emailResult = await emailResponse.json();
      const email = emailResult.email;
      const access = await fetch(`https://us-central1-virtuaaliset-asuntomessu-dad9b.cloudfunctions.net/handleMobileCode?code=${userCode}&email=${email}`)
      const data = await access.text();
  
      if (data === "Success!" || data === 'Code is now reserved!') {
        firebase.updateAccessPermissions({code: userCode, email: email})
      } 
      else return
      return token;
    }
    else return
  } catch (error) {
    console.error('Error occurred:', error);
  }
}