import firebaseConfig from "./config"
import app from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/storage"

app.initializeApp(firebaseConfig)

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      this.auth = app.auth()
      this.db = app.firestore()
      this.functions = app.functions()
      this.storage = app.storage()
    }
  }

  async register({ email, password }) {
    return this.auth.createUserWithEmailAndPassword(email, password)
  }

  async signInWithToken({ token }) {
    return this.auth.signInWithCustomToken(token)
  }
  
  async checkUserCode({ code }) {
    const checkUserCodeCallable = this.functions.httpsCallable("checkUserCode")
    return checkUserCodeCallable({
      code,
    })
  }

  async getGestData({ email }) {
    const getGestDataCallable = this.functions.httpsCallable("getNewGestData")
    return getGestDataCallable({
      email,
    })
  }

  async checkRegisteredUserCode({ email, code }) {
    const checkRegisteredUserCodeCallable = this.functions.httpsCallable(
      "checkRegisteredUserCode"
    )
    return checkRegisteredUserCodeCallable({
      email,
      code,
    })
  }

  async generateToken(uid) {
    const generateTokenCallable = this.functions.httpsCallable('generateTestToken');
    return generateTokenCallable({ uid });
  }

  async checkRegisteredCode({ email }) {
    const checkRegisteredCodeCallable = this.functions.httpsCallable(
      "checkRegisteredCode"
    )
    return checkRegisteredCodeCallable({
      email,
    })
  }

  async reserveUserCode({ email }) {
    const reserveUserCodeCallable = this.functions.httpsCallable(
      "reserveUserCode"
    )
    return reserveUserCodeCallable({ email })
  }

  async checkUsedUserCode({ code }) {
    const checkUsedUserCodeCallable = this.functions.httpsCallable(
      "checkUsedUserCode"
    )
    return checkUsedUserCodeCallable({
      code,
    })
  }
  
  async updateAccessPermissions({ code, email }) {
    const updateAccessPermissionsCallable = this.functions.httpsCallable(
      "updateAccessPermissions"
    )
    return updateAccessPermissionsCallable({
      code,
      email
    })
  }

  async checkUser({ email }) {
    const checkUserCallable = this.functions.httpsCallable("checkUser")
    return checkUserCallable({
      email,
    })
  }

  async getUser({ email }) {
    const getUserCallable = this.functions.httpsCallable("getUser")
    return getUserCallable({
      email,
    })
  }

  async deleteUser({ email }) {
    const deleteUserCallable = this.functions.httpsCallable("deleteUser")
    return deleteUserCallable({
      email,
    })
  }

  async getPhone({ email }) {
    const getPhoneCallable = this.functions.httpsCallable("getPhone")
    return getPhoneCallable({
      email,
    })
  }

  async deletePhone({ email }) {
    const deletePhoneCallable = this.functions.httpsCallable("deletePhone")
    return deletePhoneCallable({
      email,
    })
  }

  async addUser({
    email,
    first_name,
    last_name,
    terms_and_conditions_acceptance,
    newsletter,
  }) {
    const addUserCallable = this.functions.httpsCallable("addUser")
    return addUserCallable({
      email,
      first_name,
      last_name,
      terms_and_conditions_acceptance,
      newsletter,
    })
  }

  async updateUser({
    email,
    first_name,
    last_name,
    terms_and_conditions_acceptance,
    newsletter,
  }) {
    const updateUserCallable = this.functions.httpsCallable("updateUser")
    return updateUserCallable({
      email,
      first_name,
      last_name,
      terms_and_conditions_acceptance,
      newsletter,
    })
  }

  async addUserPhone({ email, phone_number }) {
    const addUserCallable = this.functions.httpsCallable("addUserPhone")
    return addUserCallable({
      email,
      phone_number,
    })
  }

  async updatePhone({
    email,
    phone_number,
  }) {
    const updatePhoneCallable = this.functions.httpsCallable("updatePhone")
    return updatePhoneCallable({
      email,
      phone_number,
    })
  }

  async addRegisteredUserCode({ email, code }) {
    const addRegisteredUserCodeCallable = this.functions.httpsCallable(
      "addRegisteredUserCode"
    )
    return addRegisteredUserCodeCallable({
      email,
      code,
    })
  }

  async login({ email, password }) {
    return this.auth.signInWithEmailAndPassword(email, password)
  }

  async resetPassword({ email }) {
    return this.auth.sendPasswordResetEmail(email)
  }

  async logout() {
    await this.auth.signOut()
  }

  async addUserAction({ email, action }) {
    const addUserActionCallable = this.functions.httpsCallable("addUserAction")
    return addUserActionCallable({
      email,
      action,
    })
  }

  async authViaGoogle(callback) {
    const provider = new app.auth.GoogleAuthProvider();
    app
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let checkUser = await this.getUser({email: result.user.email})
  
        let userRecord;
        if (!checkUser.data) {
          userRecord = await this.addUser({
            email: result.user.email
          });
        } else {
          userRecord = checkUser;
        }
  
        callback(userRecord);
      })
      .catch(error => {
        console.error(error);
      });
  }
  
  
  async authViaApple(callback) {
    // Reference: https://firebase.google.com/docs/auth/web/apple
    const provider = new app.auth.OAuthProvider('apple.com');
    app
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let checkUser = await this.getUser({email: result.user.email})
  
        let userRecord;
        if (!checkUser.data) {
          userRecord = await this.addUser({
            email: result.user.email
          });
        } else {
          userRecord = checkUser;
        }
  
        callback(userRecord);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async authViaFacebook(callback) {
    // https://firebase.google.com/docs/auth/web/facebook-login?authuser=0
    const provider = new app.auth.FacebookAuthProvider()
    app
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        //console.log(result);
        //console.log(result.user.email);
        callback(result.user.email)
      })
      .catch(error => {
        // console.log(error)
      })
  }
}

let firebaseInstance

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase()
    return firebaseInstance
  } else if (firebaseInstance) {
    return firebaseInstance
  } else {
    return null
  }
}

export default getFirebaseInstance
