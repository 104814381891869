const firebaseConfig = {
  apiKey: "AIzaSyCu3NzBO-yQ24qLXSBjI9XuNgI-8N8Jqa0",
  authDomain: "virtuaaliset-asuntomessu-dad9b.firebaseapp.com",
  databaseURL: "https://virtuaaliset-asuntomessu-dad9b.firebaseio.com",
  projectId: "virtuaaliset-asuntomessu-dad9b",
  storageBucket: "virtuaaliset-asuntomessu-dad9b.appspot.com",
  messagingSenderId: "162538877690",
  appId: "1:162538877690:web:c0155bf33bd43da76e8f43",
  measurementId: "G-QT67WTYCF2"
};

export default firebaseConfig;